import React from "react"
import "./css/Common.css"
import aboutimage from "../assets/aboutphoto.jpeg"
import location from "../assets/location.png"
import img1220 from "../assets/1220.jpg"
import img0845 from "../assets/0845.jpg"
import img0982 from "../assets/0982.jpg"
import img1053 from "../assets/1053.jpg"

const About = () => {
  return (
    <div className="showcase" id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 order-2 md:order-1">
            {" "}
            <img src={aboutimage} className="mx-auto  w-max  md:mt-0 !rounded-lg" alt="" />
            <img src={img1220} className="mx-auto  w-max  md:mt-0 !rounded-lg" alt="" />
            <img src={img0845} className="mx-auto  w-max  md:mt-0 !rounded-lg" alt="" />
            <img src={img0982} className="mx-auto  w-max md:mt-0 !rounded-lg" alt="" />{" "}
            <img src={img1053} className="mx-auto  w-max md:mt-0 !rounded-lg" alt="" />
          </div>

          <div className="col-xs-12 col-md-6 order-1 mt-24 md:mt-0">
            <div className="md:ml-12 showcase-text">
              <h2 className="pb-1 border-b-[3px] border-[#203519] w-max">About Us</h2>

              <div>
                <h3 className="underline">Who are we and what we do?</h3>
                <p>
                  Jambo Tree is an innovative, women-led initiative that provides Nature - based solutions that seek to help address climate change by cultivating indigenous African seedlings, and trees by supplying local & worldwide export markets, Jambo tree was set up in 2017, to respond to the demands of Kenyan indigenous tree species from landscape & horticultural companies in the middle east market.
                  <br /> <br />
                  Our farm, located at the foot of Mount Kilimanjaro in the Kimana, Loitoktok area of Kajiado County, Kenya, cultivates African indigenous tree varieties including Acacia and Adansonia (Baobab) seeds, seedlings and trees, which are drought tolerant and require low maintenance once they are established.
                  <br /> <br />

                  <h3 className="underline">Our Consultancy Services</h3>
                  Jambo Tree offers climate action innovation, driving transformative change across policy, technology, finance, and community behaviour. We offer organizations support and advice that promotes sustainable economic growth while addressing the urgent need to mitigate climate change  such as <b>Green Audits</b>, development and delivery of capacity building/training sessions/workshops , ESG (Environmental, Social, Governance ) analysis  and Carbon Market (ITMOs) Development and strategies, which involves implementation plan and develop/improve the long-term low carbon development strategy, and GHG emissions inventory reports that clearly defines the UN SDG’s ( sustainable development goals) impacts as part of the Net Zero agenda. We have broken new grounds and have become experts in Agro-Forestry, and carbon sequestration projects.
                  <br /> <br />

                  <h3 className="underline">Our Projects</h3>
                  Our global experience in working on sustainability projects in the UK, Brazil, UAE, Kenya and Somalia, enables us to provide analysis & create strategies for mobilization of climate finance and Carbon markets trading. We foster collaborations and partnerships between different stakeholders in the <b>“Green Space” </b>
                  <br /> <br />
                  Our latest assignment (January 2025) commissioned by Capital Markets of Kenya, under the Ministry of Trade, is to undertake a national <b>Carbon Markets Assessment of Kenya</b>, involves the scoping and assessment of national carbon space including; quantifying and assessing the size of Kenya’s Carbon Markets Ecosystem, state, scale, players, features and trends of Carbon Trading in Kenya, stakeholder identification, mapping and assessment, state of readiness vis-a-vis carbon credits trading on exchanges, platforms and marketplaces, Carbon market’s structure analysis and a review and gap analysis of the legal and regulatory environment of carbon practice and markets in Kenya, while ensuring the full integration of requirements and attributes as espoused by; Paris Agreement, UNFCCC, IPCC and the Climate Change Act 2016
                  <br /> <br />
                  Jambo Tree, was involved in ground breaking research initiative in <b>Brazil</b>, in 2023 We were involved in creating an environmental management system (EMS) for Porte Alegre & Rio Grande Ports. This project involved <b>Blue carbon projects</b> assessment of the Blue Economy, by Effective analytics, technical testing and monitoring of CO2 emissions and sequestrations, Surface water and leachate monitoring, Dust deposition gauge monitoring, Noise monitoring, Rodents and mosquito control, Respirable dust, asbestos dust and VOC [volatile organic compounds] monitoring .This also drove the implementation of Green Shipping Corridor partnerships, and application of   International Organization for Standardization (ISO) ISO 14000 certification, which provides series of standards that provide a framework for environmental management, measurement, evaluation, and auditing,
                  <br /> <br />
                  Our <b>faith and restoration project</b> in Partnership with <b>WWF</b>, included supplying of seedlings, training, advocacy and technical support in Agro- Forestry which culminated in the first interfaith collaboration between farmers in the Kajiado South/ Amboseli landscape. This initiative led to the first Green Mosques & churches initiatives and linking farmers to the Voluntary Carbon Markets.  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
